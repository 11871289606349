import { IObraProyecto, IObraProyectoResponse } from '../models/interfaces';

export const ObraProyectoAdapter = (
	payload: IObraProyectoResponse[]
): IObraProyecto[] =>
	payload
		? payload.map(op => ({
				id: op.id,
				nombre: op.nombre,
				ubicacion: op.ubicacion,
				descripcion: op.descripcion,
		  }))
		: [];
