import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
	AgregarUsuarioLectoraAdapter,
	ObraProyectoAdapter,
} from '../../adapters';
import TitleComponent from '../../components/TitleComponent';
import { ROUTES } from '../../constants/rutas';
import { UsuarioLectoraDefault } from '../../defaults';
import useError from '../../hooks/useError';
import useObrasProyectos from '../../hooks/useObrasProyectos';
import { IAgregarUsuarioLectora, IObraProyecto } from '../../models/interfaces';
import { agregarUsuarioLectoraService } from '../../services';
import { agregarUsuarioLectoraValidationSchema } from '../../utils/validations';

/* eslint-disable react-hooks/exhaustive-deps */
export default function AgregarUsuarioPage() {
	const [obrasProyectos, setObrasProyectos] = useState<IObraProyecto[]>([]);
	const { data: obrasProyectosList, error } = useObrasProyectos();
	const { ErrorAlert, setError } = useError();
	const navigate = useNavigate();

	const {
		handleSubmit,
		register,
		setValue,
		formState: { errors },
	} = useForm<IAgregarUsuarioLectora>({
		defaultValues: UsuarioLectoraDefault,
		resolver: yupResolver(agregarUsuarioLectoraValidationSchema),
		mode: 'all',
	});

	const { mutate: registrarUsuarioLectora, isLoading } = useMutation({
		mutationKey: ['agregar-usuario-lectora'],
		mutationFn: agregarUsuarioLectoraService,
		onSuccess: () => {
			navigate(ROUTES.USUARIOS.PATH);
		},
		onError: error => setError(error),
	});

	const guardarUsuario = payload =>
		registrarUsuarioLectora(AgregarUsuarioLectoraAdapter(payload));

	useEffect(() => {
		if (obrasProyectosList) {
			const obrasProyectosFormateadas = ObraProyectoAdapter(
				obrasProyectosList?.data
			);
			setObrasProyectos(obrasProyectosFormateadas);
			setValue('idObraProyecto', obrasProyectosFormateadas[0].id);
		}
	}, [obrasProyectosList]);

	useEffect(() => setError(error), [error]);

	return (
		<div className="container mt-5">
			<ErrorAlert />
			<TitleComponent titulo="Nueva Lectora" ruta={ROUTES.USUARIOS.PATH} />
			<form onSubmit={handleSubmit(guardarUsuario)}>
				<Row className="d-flex align-items-center justify-content-center mt-4">
					<Col className="w-50" xs={12} sm={12} md={12}>
						<Row className="mb-4">
							<Col xs={12} sm={12} md={12}>
								<Form.Group>
									<Form.Label>Usuario</Form.Label>
									<Form.Control
										placeholder="Usuario"
										isInvalid={!!errors.userName}
										{...register('userName')}
									/>
									<Form.Control.Feedback className="d-block" type="invalid">
										{errors?.userName?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col xs={12} sm={12} md={12}>
								<Form.Group>
									<Form.Label>Obra o Proyecto</Form.Label>
									<Form.Select
										isInvalid={!!errors.idObraProyecto}
										{...register('idObraProyecto')}
									>
										{obrasProyectos.map(({ id, nombre }) => (
											<option key={id} value={id}>
												{nombre}
											</option>
										))}
									</Form.Select>
									<Form.Control.Feedback className="d-block" type="invalid">
										{errors?.idObraProyecto?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={12} sm={12} md={12}>
								<Button type="submit" className="w-100" disabled={isLoading}>
									{isLoading ? (
										<>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											&nbsp;&nbsp;Guardando Lectora
										</>
									) : (
										'Guardar Lectora'
									)}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</form>
		</div>
	);
}
