import { useQuery } from '@tanstack/react-query';

import { usuariosService } from '../services';

export default function useUsuarios() {
	return useQuery({
		queryKey: ['usuarios'],
		queryFn: usuariosService,
		refetchOnWindowFocus: false,
		retry: 2,
	});
}
