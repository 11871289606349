import { ROLES } from '../constants';

export default function useRole() {
	const rolesList =
		JSON.parse(localStorage.getItem('credenciales'))?.roles || [];

	const roleIsMatch = (
		role: string | string[],
		route?: { PATH: string; ROLES: ROLES[] }
	): boolean => {
		if (typeof role === 'string') {
			// Se hace para verificar si un usuario logueado tiene el rol que busca validar
			return rolesList
				.map(role => role.toLowerCase())
				.some(rl => rl === role.toLowerCase());
		} else if (route) {
			// Se hace el match con los roles de la ruta a donde intenta acceder, esto para proteger las rutas
			return role.some(roleItem =>
				route.ROLES.map(role => role.toLowerCase()).includes(
					roleItem.toLowerCase()
				)
			);
		}
	};

	return {
		roleIsMatch,
	};
}
