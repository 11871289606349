import { useQuery } from '@tanstack/react-query';

import { obrasProyectosService } from '../services';

export default function useObrasProyectos() {
	return useQuery({
		queryKey: ['obrasProyectos'],
		queryFn: obrasProyectosService,
		refetchOnWindowFocus: false,
		retry: 2,
	});
}
