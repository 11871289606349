import { IUsuario, IUsuarioResponse } from '../models/interfaces';

export const usuariosAdapter = (
	usuarios: IUsuarioResponse[] | undefined
): IUsuario[] =>
	usuarios
		? usuarios?.map(
				({ id, userName, obraProyectoId, obraProyectoDescripcion }) => ({
					id,
					nombre: userName,
					idObraProyecto: obraProyectoId,
					obraProyecto: obraProyectoDescripcion,
				})
		  )
		: [];
