import { ROLES } from './roles';

const { ADMINISTRADOR, MONITOR } = ROLES;

export const ROUTES = {
	ROOT: {
		PATH: '/',
		ROLES: [],
	},
	HOME: {
		PATH: '/home',
		ROLES: [ADMINISTRADOR, MONITOR],
	},
	USUARIOS: {
		PATH: '/usuarios',
		ROLES: [ADMINISTRADOR],
	},
	AGREGAR_USUARIO: {
		PATH: '/agregar-usuario',
		ROLES: [ADMINISTRADOR],
	},
};
