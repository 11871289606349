import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ContacLogo from '../assets/images/contac.png';
import { ROLES, ROUTES } from '../constants';
import useAppData from '../hooks/useAppData';
import useRole from '../hooks/useRole';
import { logoutService } from '../services/auth.service';

import '../styles/components/NavMenu.component.scss';

export default function NavMenu() {
	const { setDataUser } = useAppData();
	const { roleIsMatch } = useRole();

	const isAdminLogged = roleIsMatch(ROLES.ADMINISTRADOR);

	return (
		<Navbar
			className="navmenu px-3"
			bg="primary"
			expand="lg"
			variant="dark"
			sticky="top"
			collapseOnSelect
		>
			<Container fluid>
				<Navbar.Brand as={Link} to={ROUTES.HOME.PATH}>
					<img src={ContacLogo} width={119} height={32} alt="Contac" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<div className="navmenu__nav-items">
						<div className="navmenu__sub-item">
							<Nav>
								<Nav.Link as={Link} to={ROUTES.HOME.PATH}>
									Acceso Personal
								</Nav.Link>
							</Nav>
							{isAdminLogged && (
								<Nav>
									<Nav.Link as={Link} to={ROUTES.USUARIOS.PATH}>
										Usuarios
									</Nav.Link>
								</Nav>
							)}
						</div>
						<Nav>
							<Nav.Link onClick={() => logoutService(setDataUser)}>
								Cerrar Sesión
							</Nav.Link>
						</Nav>
					</div>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
