// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import { IconButton } from '@mui/material';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { usuariosAdapter } from '../../adapters';
import TitleComponent from '../../components/TitleComponent';
import { ROUTES } from '../../constants/rutas';
import useError from '../../hooks/useError';
import useUsuarios from '../../hooks/useUsuarios';
import { IUsuario } from '../../models/interfaces';

/* eslint-disable react-hooks/exhaustive-deps */
export default function UsuariosPage() {
	const [usuarios, setUsuarios] = useState<IUsuario[]>([]);

	const { data: usuariosList, error: errorUsuarios } = useUsuarios();

	const { ErrorAlert, setError } = useError();

	useEffect(() => {
		setUsuarios(usuariosAdapter(usuariosList?.data));
	}, [usuariosList]);

	useEffect(() => {
		setError(errorUsuarios);
	}, [errorUsuarios]);

	return (
		<div className="container mt-5">
			<ErrorAlert />
			<TitleComponent titulo="Lectoras" />
			<Row className="mt-4">
				<Col
					className="d-flex align-items-center justify-content-end"
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Link to={ROUTES.AGREGAR_USUARIO.PATH}>
						<Button variant="primary">Agregar Lectora</Button>
					</Link>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col xs={12} sm={12} md={12}>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Usuario Lectora</th>
								<th>Obra o Proyecto</th>
								{/*<th>Acciones</th>*/}
							</tr>
						</thead>
						<tbody>
							{usuarios.length ? (
								<>
									{usuarios.map(({ id, nombre, obraProyecto }) => (
										<tr key={id}>
											<td>{nombre}</td>
											<td>{obraProyecto}</td>
											{/*<td>*/}
											{/*	<IconButton size="small">*/}
											{/*		<EditIcon fontSize="small" />*/}
											{/*	</IconButton>*/}
											{/*	&nbsp;&nbsp;*/}
											{/*	<IconButton size="small">*/}
											{/*		<DeleteIcon fontSize="small" />*/}
											{/*	</IconButton>*/}
											{/*</td>*/}
										</tr>
									))}
								</>
							) : (
								<tr>
									<td colSpan={3}>No hay lectoras registradas.</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
		</div>
	);
}
