import { IAgregarUsuarioLectoraRequest } from '../models/interfaces';
import http from '../utils/http';

export const agregarUsuarioLectoraService = async (
	payload: IAgregarUsuarioLectoraRequest
) => {
	const result = await http.post(
		'ObraProyectoUser/CreaUsuarioMonitor',
		payload
	);
	return result;
};
