import {
	IAgregarUsuarioLectora,
	IAgregarUsuarioLectoraRequest,
} from '../models/interfaces';

export const AgregarUsuarioLectoraAdapter = ({
	userName,
	idObraProyecto,
}: IAgregarUsuarioLectora): IAgregarUsuarioLectoraRequest => ({
	userName,
	obraProyectoId: idObraProyecto,
});
