import * as Yup from 'yup';

import { ErrorMessages, REGEX } from '../../constants';

export const agregarUsuarioLectoraValidationSchema = Yup.object({
	userName: Yup.string()
		.matches(REGEX.NO_ESPACIOS, ErrorMessages.NoEspaces)
		.required(ErrorMessages.Required),
	idObraProyecto: Yup.number().min(1, ErrorMessages.Required),
});
